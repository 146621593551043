import { create } from '@storybook/theming/create';

export default create({
  base: 'light',

  brandTitle: 'Rolex Web Components',
  brandImage: 'https://content.rolex.com/v7/dam/homepage/rolex-search.jpg',
  brandTarget: '_self',

  appBg: '#F8F8F8',
  colorPrimary: '#006039',
  colorSecondary: '#006039',
  appContentBg: '#FFFFFF',
  appBorderRadius: 12,
});
